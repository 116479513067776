import { h } from 'preact';
import { useState } from 'preact/hooks';
import { useTranslation } from 'react-i18next';

// A map of all flags we have.
const FLAGS = {
	en: 'https://image.flaticon.com/icons/png/512/555/555526.png',
	fr: 'https://image.flaticon.com/icons/png/512/555/555602.png',
	de: 'https://image.flaticon.com/icons/png/512/555/555613.png',
	es: 'https://image.flaticon.com/icons/png/512/555/555635.png',
	pt: 'https://image.flaticon.com/icons/png/512/555/555505.png'
};

// Default flag in case we're missing one
const DEFAULT_FLAG = FLAGS.en;

// The language names, as written in their own language.
const LANG_NAMES = {
	en: 'English',
	fr: 'Français',
	de: 'Deutsch',
	es: 'Español',
	pt: 'Portugues'
};

const CaretDown = () => (
	<span style="vertical-align: middle; right: 8px;  position: absolute;">
		<svg viewBox="0 0 1024 1024" focusable="false" width="1em" height="1em" fill="#4a5061" aria-hidden="true" style="margin-top: 3px">
			<path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path>
		</svg>
	</span>
);

// Renders the language switcher.
const LangSwitcher = () => {
	const { t, i18n } = useTranslation();
	const [isOpen, setOpen] = useState(false);

	return <div class="switch-lang">
		<div onClick={() => setOpen(!isOpen)} class="current-lang"><img class="lang-flag" src={FLAGS[i18n.language] || DEFAULT_FLAG} />
			<p>{LANG_NAMES[i18n.language] || 'English'} <CaretDown /></p>
		</div>

		<div class={isOpen ? 'lang-dropdown open' : 'lang-dropdown'}>
			{Object.keys(LANG_NAMES).map(lang => <LanguageOption lang={lang} setOpen={setOpen} />)}
		</div>
	</div>
}

const LanguageOption = ({ lang, setOpen }) => {
	const { i18n } = useTranslation();

	return (
		<div onClick={() => { i18n.changeLanguage(lang); setOpen(false) }} class="selecting-lang">
			<img class="lang-flag" src={FLAGS[lang] || DEFAULT_FLAG} />
			<p>{LANG_NAMES[lang] || 'English'}</p>
		</div>
	);
}

const App = () => {
	const { t } = useTranslation();

	return <div id="app" class="container">

		<div class="top-nav">
			<a href="https://bitgate.com" target="_blank"><img src="/assets/bitgate-white.svg" width="124" alt="Company logo" /></a>

			<LangSwitcher />
		</div>

		<div class="background-card">
			<h2>{t('title')}</h2>

			<form action="/action_page.php">
				<p>{t('subtitle')}</p>
				<div class="switch-field">
					<input type="radio" id="radio-three" name="switch-two" value="yes" checked />
					<label for="radio-three" class="btn btn-primary btn-sm">
						<svg viewBox="64 64 896 896" focusable="false" data-icon="smile" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M288 421a48 48 0 1096 0 48 48 0 10-96 0zm352 0a48 48 0 1096 0 48 48 0 10-96 0zM512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm263 711c-34.2 34.2-74 61-118.3 79.8C611 874.2 562.3 884 512 884c-50.3 0-99-9.8-144.8-29.2A370.4 370.4 0 01248.9 775c-34.2-34.2-61-74-79.8-118.3C149.8 611 140 562.3 140 512s9.8-99 29.2-144.8A370.4 370.4 0 01249 248.9c34.2-34.2 74-61 118.3-79.8C413 149.8 461.7 140 512 140c50.3 0 99 9.8 144.8 29.2A370.4 370.4 0 01775.1 249c34.2 34.2 61 74 79.8 118.3C874.2 413 884 461.7 884 512s-9.8 99-29.2 144.8A368.89 368.89 0 01775 775zM664 533h-48.1c-4.2 0-7.8 3.2-8.1 7.4C604 589.9 562.5 629 512 629s-92.1-39.1-95.8-88.6c-.3-4.2-3.9-7.4-8.1-7.4H360a8 8 0 00-8 8.4c4.4 84.3 74.5 151.6 160 151.6s155.6-67.3 160-151.6a8 8 0 00-8-8.4z"></path></svg>
						{t('rating_great')}
					</label>
					<input type="radio" id="radio-four" name="switch-two" value="maybe" />
					<label for="radio-four" class="btn btn-primary btn-sm">
						<svg viewBox="64 64 896 896" focusable="false" data-icon="meh" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M288 421a48 48 0 1096 0 48 48 0 10-96 0zm352 0a48 48 0 1096 0 48 48 0 10-96 0zM512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm263 711c-34.2 34.2-74 61-118.3 79.8C611 874.2 562.3 884 512 884c-50.3 0-99-9.8-144.8-29.2A370.4 370.4 0 01248.9 775c-34.2-34.2-61-74-79.8-118.3C149.8 611 140 562.3 140 512s9.8-99 29.2-144.8A370.4 370.4 0 01249 248.9c34.2-34.2 74-61 118.3-79.8C413 149.8 461.7 140 512 140c50.3 0 99 9.8 144.8 29.2A370.4 370.4 0 01775.1 249c34.2 34.2 61 74 79.8 118.3C874.2 413 884 461.7 884 512s-9.8 99-29.2 144.8A368.89 368.89 0 01775 775zM664 565H360c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h304c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path></svg>
						{t('rating_average')}
					</label>
					<input type="radio" id="radio-five" name="switch-two" value="no" />
					<label for="radio-five" class="btn btn-primary btn-sm">
						<svg viewBox="64 64 896 896" focusable="false" data-icon="frown" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M288 421a48 48 0 1096 0 48 48 0 10-96 0zm352 0a48 48 0 1096 0 48 48 0 10-96 0zM512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm263 711c-34.2 34.2-74 61-118.3 79.8C611 874.2 562.3 884 512 884c-50.3 0-99-9.8-144.8-29.2A370.4 370.4 0 01248.9 775c-34.2-34.2-61-74-79.8-118.3C149.8 611 140 562.3 140 512s9.8-99 29.2-144.8A370.4 370.4 0 01249 248.9c34.2-34.2 74-61 118.3-79.8C413 149.8 461.7 140 512 140c50.3 0 99 9.8 144.8 29.2A370.4 370.4 0 01775.1 249c34.2 34.2 61 74 79.8 118.3C874.2 413 884 461.7 884 512s-9.8 99-29.2 144.8A368.89 368.89 0 01775 775zM512 533c-85.5 0-155.6 67.3-160 151.6a8 8 0 008 8.4h48.1c4.2 0 7.8-3.2 8.1-7.4C420 636.1 461.5 597 512 597s92.1 39.1 95.8 88.6c.3 4.2 3.9 7.4 8.1 7.4H664a8 8 0 008-8.4C667.6 600.3 597.5 533 512 533z"></path></svg>
						{t('rating_bad')}
					</label>
				</div>

				<p>{t('additional_feedback')}</p>
				<textarea class="form-control" id="feedback-field" placeholder={t('feedback_placeholder')} rows="6"></textarea>
				<br />
				<button style="float:right;" type="submit" class="btn btn-primary btn-lg">{t('feedback_submit')}</button>
				<div style="clear: both; margin-bottom: 12px;"></div>
			</form>
		</div>

		<hr style="	margin-top: 32px; margin-bottom: 32px;"></hr>

		<div class="ticket-details background-card" >
			<h3>Reply on: "Bug report from alixers118@gmail.com" <span>#339</span></h3>
			<span>Thu 22 Jul. at 12:49</span>

			<div style="display: flex; justify-content: space-between; align-items: top; 	margin: 32px 0;">
				<div class="agent-avatar"></div>
				<div class="ticket-message">
					<p>Hi Adventurer,</p>

					<p>Unfortunately, this is currently indeed a bug in our game. Due to certain restrictions in our UI design, this will not be fixed until our next major release in which we address tablets such as an iPad.</p>

					<p>Some users have found that rotating it may make the edges work just enough to tap them, but this varies per model.</p>

					<p>Sorry we couldn't be of more service here. Hope it helps somewhat!</p>

					<div class="email-sig">
						<p>Stay awesome,</p>

						<p>The Curse of Aros team.</p>
					</div>
				</div>
			</div>

		</div>

		<div class="footer-stuff text-muted footer-link"><p>{t('powered_by')} <a href="https://gamesupport.gg" target="_blank">GameSupport</a>.</p></div>
	</div>
}

export default App;
