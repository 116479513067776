import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './translations/en.json';
import de from './translations/de.json';
import fr from './translations/fr.json';
import es from './translations/es.json';
import pt from './translations/pt.json';

const resources = {
    en: { translation: en },
    de: { translation: de },
    fr: { translation: fr },
    es: { translation: es },
    pt: { translation: pt },
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: 'en',
        supportedLngs: ['en', 'fr', 'es', 'de', 'pt'],
        debug: true,

        interpolation: {
            escapeValue: false,
        },

        react: {
            useSuspense: false,
        }
    });


export default i18n;
